import React from "react";

import Layout from "components/Layout";
import Seo from "components/Layout/components/Seo";
import Content from "page_components/info_page/Content";

import Logo from "icons/big-logo.svg";

const ThankYou = () => {
  return (
    <Layout>
      <Content
        icon={<Logo />}
        title="Dziękujemy"
        text="za wysłanie formularza"
      />
    </Layout>
  );
};

export const Head = () => <Seo title="Wiadomość wysłana | Kameralna Dolina" />;

export default ThankYou;
